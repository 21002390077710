import makeStyles from '@material-ui/core/styles/makeStyles'
import UploadBackground from 'assets/upload-background.jpeg'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 50px)',
    backgroundImage: `url("${UploadBackground}")`,
    backgroundRepeat: 'unset',
    backgroundSize: 'cover',
  },

  statusTextWrapper: {
    width: '60%',
    margin: '0 0 20px',
    padding: '10px 10px 0 10px',
    backgroundColor: 'white',
    borderRadius: '10px',
  },

  statusText: {
    margin: '0 0 10px',
  },

  statusTextBald: {
    fontWeight: '600',
  },

  statusTextLink: {
    color: 'black',
  },

  uploadBoxWrapper: {
    width: '280px',
    marginRight: '20px',
    padding: '30px 0 10px',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 12px 0 rgb(0 0 0 / 10%), 0 10px 30px 0 rgb(0 0 0 / 20%)',
  },

  uploadButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '0 10px',
  },

  uploadButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
    color: 'white',
    backgroundColor: '#0070cd',
    borderRadius: '50%',
    cursor: 'pointer',
  },

  uploadButtonText: {
    display: 'inline-block',
    margin: '0',
    marginInlineStart: '10px',
    cursor: 'pointer',
  },

  uploadedFileName: {
    margin: '0',
    marginBottom: '20px',
    padding: '0 10px',
    fontSize: '16px',
    fontWeight: '600',
  },

  uploadButtonInput: {
    display: 'none',
  },

  insuranceCompaniesMenu: {
    marginBottom: '30px',
    padding: '0 10px',
  },

  uploadButtonConfirm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '130px',
    height: '36px',
    margin: 'auto',
    marginTop: '20px',
    padding: '10px 40px',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    backgroundColor: props => (props.confirmButtonEnabled && '#0070cd') || '#babcbf',
    border: '0',
    borderRadius: '20px',
    cursor: 'pointer',
  },

  divider: {
    border: '1px solid #d4d7d9',
  },
}))

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}
