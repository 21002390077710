import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
    position :"relative"

  },
  paginationWrapper: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    overflow:'none'
  },
  tableHead: {
    fontWeight: 700,
    padding: '20px'
  },
  deleteBtn: {
    color: theme.palette.danger.main
  },
  tableBody: {
  }
}))
