const dashboardActionTypes = {
  SET_SELECTED_ITEM: "SET_SELECTED_ITEM",
  SET_SELECTED_COUNTRY: "SET_SELECTED_COUNTRY",
  GET_RELATED_SPECIALITIES_FOR_MAIN: "GET_RELATED_SPECIALITIES_FOR_MAIN",
  GET_RELATED_SPECIALITIES_FOR_MAIN_SUCCESS: "GET_RELATED_SPECIALITIES_FOR_MAIN_SUCCESS",
  GET_RELATED_SPECIALITIES_FOR_MAIN_FAIL: "GET_RELATED_SPECIALITIES_FOR_MAIN_FAIL",
  GET_RELATED_SPECIALITIES_FOR_SUB: "GET_RELATED_SPECIALITIES_FOR_SUB",
  GET_RELATED_SPECIALITIES_FOR_SUB_SUCCESS: "GET_RELATED_SPECIALITIES_FOR_SUB_SUCCESS",
  GET_RELATED_SPECIALITIES_FOR_SUB_FAIL: "GET_RELATED_SPECIALITIES_FOR_SUB_FAIL",
  ADD_AREA: "ADD_AREA",
  ADD_AREA_SUCCESS: "ADD_AREA_SUCCESS",
  ADD_AREA_FAIL: "ADD_AREA_FAIL",
  ADD_CITY: "ADD_CITY",
  ADD_CITY_SUCCESS: "ADD_CITY_SUCCESS",
  ADD_CITY_FAIL: "ADD_CITY_FAIL",
  ADD_INSURANCE: "ADD_INSURANCE",
  ADD_INSURANCE_SUCCESS: "ADD_INSURANCE_SUCCESS",
  ADD_INSURANCE_FAIL: "ADD_INSURANCE_FAIL",
  ADD_SPECIALITY: "ADD_SPECIALITY",
  ADD_SPECIALITY_SUCCESS: "ADD_SPECIALITY_SUCCESS",
  ADD_SPECIALITY_FAIL: "ADD_SPECIALITY_FAIL",
  ADD_AREA_NEIGHBOUR: 'ADD_AREA_NEIGHBOUR',
  ADD_AREA_NEIGHBOUR_SUCCESS: 'ADD_AREA_NEIGHBOUR_SUCCESS',
  ADD_AREA_NEIGHBOUR_FAIL: 'ADD_AREA_NEIGHBOUR_FAIL',
  EDIT_AREA: "EDIT_AREA",
  EDIT_AREA_SUCCESS: "EDIT_AREA_SUCCESS",
  EDIT_AREA_FAIL: "EDIT_AREA_FAIL",
  EDIT_CITY: "EDIT_CITY",
  EDIT_CITY_SUCCESS: "EDIT_CITY_SUCCESS",
  EDIT_CITY_FAIL: "EDIT_CITY_FAIL",
  EDIT_INSURANCE: "EDIT_INSURANCE",
  EDIT_INSURANCE_SUCCESS: "EDIT_INSURANCE_SUCCESS",
  EDIT_INSURANCE_FAIL: "EDIT_INSURANCE_FAIL",
  EDIT_SPECIALITY: "EDIT_SPECIALITY",
  EDIT_SPECIALITY_SUCCESS: "EDIT_SPECIALITY_SUCCESS",
  EDIT_SPECIALITY_FAIL: "EDIT_SPECIALITY_FAIL",
  GET_AREA: "GET_AREA",
  GET_AREA_SUCCESS: "GET_AREA_SUCCESS",
  GET_AREA_FAIL: "GET_AREA_FAIL",
  GET_CITY: "GET_CITY",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  GET_CITY_FAIL: "GET_CITY_FAIL",
  GET_INSURANCE: "GET_INSURANCE",
  GET_INSURANCE_SUCCESS: "GET_INSURANCE_SUCCESS",
  GET_INSURANCE_FAIL: "GET_INSURANCE_FAIL",
  GET_MAIN_SPECIALITY: "GET_MAIN_SPECIALITY",
  GET_MAIN_SPECIALITY_SUCCESS: "GET_MAIN_SPECIALITY_SUCCESS",
  GET_MAIN_SPECIALITY_FAIL: "GET_MAIN_SPECIALITY_FAIL",
  GET_SUB_SPECIALITY: "GET_SUB_SPECIALITY",
  GET_SUB_SPECIALITY_SUCCESS: "GET_SUB_SPECIALITY_SUCCESS",
  GET_SUB_SPECIALITY_FAIL: "GET_SUB_SPECIALITY_FAIL",
  GET_AREA_NEIGHBOURS: 'GET_AREA_NEIGHBOURS',
  GET_AREA_NEIGHBOURS_SUCCESS: 'GET_AREA_NEIGHBOURS_SUCCESS',
  GET_AREA_NEIGHBOURS_FAIL: 'GET_AREA_NEIGHBOURS_FAIL',
  DELETE_AREA_NEIGHBOUR: 'DELETE_AREA_NEIGHBOUR',
  DELETE_AREA_NEIGHBOUR_SUCCESS: 'DELETE_AREA_NEIGHBOUR_SUCCESS',
  DELETE_AREA_NEIGHBOUR_FAIL: 'DELETE_AREA_NEIGHBOUR_FAIL',
  TOGGLE_SHOW_FAIL: "TOGGLE_SHOW_FAIL",
  EMPTY_AREAS_AND_AREA_NEIGHBOURS_STATE:"EMPTY_AREAS_AND_AREA_NEIGHBOURS_STATE",
  SET_SELECTED_AREA_OBJ  :"SET_SELECTED_AREA_OBJ",
  GET_ALL_INSURANCE_COMPANIES: 'GET_ALL_INSURANCE_COMPANIES',
  GET_ALL_INSURANCE_COMPANIES_SUCCESS: 'GET_ALL_INSURANCE_COMPANIES_SUCCESS',
  GET_ALL_INSURANCE_COMPANIES_FAIL: 'GET_ALL_INSURANCE_COMPANIES_FAIL',
  GET_PROCESSED_DOCUMENT_STATUS: 'GET_PROCESSED_DOCUMENT_STATUS',
  GET_PROCESSED_DOCUMENT_STATUS_SUCCESS: 'GET_PROCESSED_DOCUMENT_STATUS_SUCCESS',
  GET_PROCESSED_DOCUMENT_STATUS_FAIL: 'GET_PROCESSED_DOCUMENT_STATUS_FAIL',
  UPLOAD_MEDICAL_CARDS: 'UPLOAD_MEDICAL_CARDS',
  UPLOAD_MEDICAL_CARDS_SUCCESS: 'UPLOAD_MEDICAL_CARDS_SUCCESS',
  UPLOAD_MEDICAL_CARDS_FAIL: 'UPLOAD_MEDICAL_CARDS_FAIL',
}
export default dashboardActionTypes
