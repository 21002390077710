import Cookies from 'js-cookie'

const dataURItoBlob = dataURI => {
  const mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const byteString = atob(dataURI.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], {type: mimeType});
  return blob;
}

export const convertFileToBlob = fileData => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.addEventListener('load', () => {
    const blob = dataURItoBlob(reader.result);
    resolve(blob)
  }, false);

  reader.readAsDataURL(fileData);
})

export const checkIfCurrentUserIsMedicalCardsUser = () => {
  if (!process.env.REACT_APP_MEDICAL_CARDS_USERS) return false;

  const medicalCardsUsers = process.env.REACT_APP_MEDICAL_CARDS_USERS.split(',');
  if (medicalCardsUsers.includes(Cookies.get('userAuth'))) 
    return true;

  return false;
}