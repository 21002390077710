import React from 'react';
import { useStyles, MenuProps } from './MedicalCardsUploader.style'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import AddIcon from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import { constants } from 'utils'

const { status } = constants;

const MedicalCardsUploader = ({     
    insuranceCompanies,
    processedDocumentStatus,
    uploadMedicalCards,
    selectedInsuranceCompany,
    setSelectedInsuranceCompany,
    medicalCardsFileData,
    handleInsuranceUploadBtnChange,
    fileInputRef,
    handleInsuranceUploadBtnClick,
  }) => {
  const classes = useStyles({
    confirmButtonEnabled: selectedInsuranceCompany && medicalCardsFileData.fileName,
  })

  const { data } = processedDocumentStatus;
  const latestLogs = (data && data.slice(0, 5)) || [];

  const generateLogCreationDate = log => {
    const creationDate = log.createdOn;
    let creationDateObj = new Date(creationDate);
    const creationDateCopy = new Date(creationDateObj);
    creationDateObj =  new Date(creationDateCopy.setHours(creationDateCopy.getHours() + 2));
    const formatOption = { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' };
    const creationDateStr = creationDateObj.toLocaleString('en-US', formatOption);
    const [, dayAndMonth, year] = creationDateStr.split(', ');
    const [month, day] = dayAndMonth.split(' ');
    const dayPartArr = ['am', 'pm'];
    let hours = creationDateObj.getHours();
    const dayPart = dayPartArr[(hours / 12) | 0];
    hours = hours % 12 || 12;
    const minutes = `0${creationDateObj.getMinutes()}`.slice(-2);
    const dateTimeStr = `${day} ${month} ${year} ${hours}:${minutes} ${dayPart}`;

    return dateTimeStr;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.uploadBoxWrapper}>
        <label for="upload-btn" className={classes.uploadButtonWrapper}>
          <div className={classes.uploadButtonIcon}>
            <AddIcon />
          </div>
          <p className={classes.uploadButtonText}>Add File</p>
        </label>
        <p className={classes.uploadedFileName}>{medicalCardsFileData.fileName}</p>
        <input 
          id="upload-btn" 
          className={classes.uploadButtonInput} 
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ref={fileInputRef}
          onChange={handleInsuranceUploadBtnChange} 
        />
        <hr className={classes.divider} />
        <div className={classes.insuranceCompaniesMenu}>
          <p>Select insurance company</p>
          <Select
            fullWidth
            value={selectedInsuranceCompany}
            onChange={(item) => {setSelectedInsuranceCompany(item.target.value)}}
            input={<Input />}
            MenuProps={MenuProps}
          >
            {insuranceCompanies &&
              insuranceCompanies.data &&
              insuranceCompanies.data.filter(insuranceCompany => ['ins368e8EG'].includes(insuranceCompany.cardProviderKey))
              .map(insuranceCompany => (
                <MenuItem
                  key={insuranceCompany.cardProviderKey}
                  value={insuranceCompany.cardProviderKey}
                >
                  {insuranceCompany.name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <hr className={classes.divider} />
        <button className={classes.uploadButtonConfirm} onClick={() => handleInsuranceUploadBtnClick()}>
          {(uploadMedicalCards.status === status.FETCHING && <CircularProgress size={26} color={'#ffffff'} />) || 'Upload'}
        </button>
      </div>
      {latestLogs.length > 0 && 
        <div className={classes.statusTextWrapper}>
          <p className={classes.statusText}><span className={classes.statusTextBald}>Last Uploaded Sheets Status</span></p>
          <hr />
          {latestLogs.map((log, index) => (
              <>
                <p className={classes.statusText}><span className={classes.statusTextBald}>Job Id: </span>{log.jobId}</p>
                <p className={classes.statusText}><span className={classes.statusTextBald}>Date: </span>{generateLogCreationDate(log)}</p>
                <p className={classes.statusText}><span className={classes.statusTextBald}>Status: </span>{log.status}</p>
                <a className={classes.statusTextLink} href={log.logsFileURL} target="_blank" rel="noopener noreferrer"><p className={classes.statusText}><span className={classes.statusTextBald}>Log Link</span></p></a>
                {index !== latestLogs.length - 1 && <hr />}
              </>
            ))}
        </div>}
    </div>
  )
};

export default MedicalCardsUploader;
