export const status = {
    SHOULD_CALL_API: 'SHOULD_CALL_API',
    FETCHING: 'FETCHING',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
}

export const mapLanguageIdsToEnglishText = {
    1: 'English',
    2: 'Arabic'
}